import { GlobalPairingRoundEdit } from '../components/Pairing/GlobalRound';

function GlobalPairingRoundEditRoute() {
  return <GlobalPairingRoundEdit />;
}

export const Component = GlobalPairingRoundEditRoute;

export function clientLoader() {
  return null;
}
